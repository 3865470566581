import { cn } from "~/libs/utils";

const Monitor: React.FC<React.SVGProps<SVGElement>> = ({ className }) => (
  <svg
    className={cn(className)}
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="100"
    fill="none"
    viewBox="0 0 100 100"
  >
    <g
      stroke="#F9709A"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="6.25"
      clipPath="url(#clip0_9559_12368)"
    >
      <path d="M76.788 70.833H23.212c-5.917 0-10.713-4.796-10.713-10.712V23.213c0-5.917 4.796-10.713 10.712-10.713h53.571c5.921 0 10.717 4.796 10.717 10.712v36.905c0 5.92-4.796 10.716-10.713 10.716M58.328 70.84l2.083 16.667M41.662 70.84l-2.084 16.667M33.914 87.5h32.167"></path>
    </g>
    <defs>
      <clipPath id="clip0_9559_12368">
        <path fill="#fff" d="M0 0h100v100H0z"></path>
      </clipPath>
    </defs>
  </svg>
);

export default Monitor;
