import * as Sentry from "@sentry/nextjs";
import React, { Component, type ErrorInfo } from "react";

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.captureException(error, {
      extra: {
        info: errorInfo,
        componentStack: errorInfo.componentStack,
        digest: errorInfo.digest,
      },
    });
    console.error("ErrorBoundary caught an error: ", error, errorInfo);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return <div className={"p-2 text-xl"}>Something went wrong.</div>;
    }

    return this.props.children;
  }
}

export { ErrorBoundary };
