import { useTranslation } from "next-i18next";
import Monitor from "~/assets/icons/monitor";

export const MobilePlug = () => {
  const { t } = useTranslation("plugs");
  return (
    <div className="flex h-full flex-col items-center justify-center gap-3 bg-white px-6 lg:hidden">
      <Monitor />
      <h1 className="max-w-[500px] text-[22px] font-bold text-button-action">
        {t("mobilePlug.title")}
      </h1>
      <p className="max-w-[500px] text-lg text-text-primary">
        {t("mobilePlug.message")}
      </p>
    </div>
  );
};
