import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "~/libs/utils";

const inputVariants = cva(
  "flex h-10 w-full text-black-70 remove-autofill rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-0  focus-visible:ring-offset-0 disabled:cursor-not-allowed disabled:opacity-50",
  {
    variants: {
      variant: {
        transparent:
          "bg-transparent border-none hover:border-none focus:border-none focus:ring-0",
        bordered:
          "border border-black-40 focus:text-text-primary font-normal rounded-sm bg-transparent",
        underlined:
          "rounded-none bg-transparent font-normal focus:text-text-primary border-b-[0.75px] border-t-0 border-l-0 border-r-0 border-black-70",
      },
      dimension: {
        sm: "h-8 px-2 text-xs",
        md: "h-10 px-3 text-sm ",
        lg: "h-12 px-4 text-base",
        xl: "h-14 px-4 text-sm",
      },
    },
    defaultVariants: {
      variant: "transparent",
      dimension: "md",
    },
  },
);

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, variant, dimension, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(inputVariants({ variant, dimension, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Input.displayName = "Input";

export { Input, inputVariants };
