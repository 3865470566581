import { TooltipProvider } from "~/components/ui/tooltip";
import { ErrorBoundary } from "~/components/ui/error-boundary";
import { type ReactNode, Suspense } from "react";
import { Spinner } from "~/components/ui/spinner";
import { MobilePlug } from "~/components/layout/plug-for-mobile";

export const LoginLayout = ({ children }: { children: ReactNode }) => {
  return (
    <div className="relative flex min-h-screen flex-row bg-white text-foreground">
      <main className="main flex flex-grow flex-col transition-all duration-150 ease-in md:ml-0">
        <TooltipProvider>
          <ErrorBoundary>
            <Suspense fallback={<Spinner />}>
              <MobilePlug />
              <div className="main-content hidden max-w-[100vw] flex-grow flex-row lg:flex">
                <div
                  className={"flex flex-1 flex-col pl-[100px] xl:pl-[200px]"}
                >
                  <div className={"flex-0"}>
                    <header className="w-full">
                      <a href={"/"}>
                        <img
                          alt="healthymind"
                          src={"/logo-beta.svg"}
                          className={"py-4"}
                        />
                      </a>
                    </header>
                  </div>
                  <div
                    className={"flex flex-1 flex-col pr-10 xl:max-w-[600px]"}
                  >
                    {children}
                  </div>
                </div>
                <div
                  className={"flex h-full flex-1 bg-cover"}
                  style={{
                    backgroundImage: "url('/side-picture-gradient.png')",
                  }}
                ></div>
              </div>
            </Suspense>
          </ErrorBoundary>
        </TooltipProvider>
      </main>
    </div>
  );
};
