import i18next from "i18next";
import { z } from "zod";
import { zodI18nMap } from "zod-i18n-map";
import translation from "zod-i18n-map/locales/uk-UA/zod.json";

//eslint-disable-next-line @typescript-eslint/no-floating-promises
i18next.init({
  lng: "uk-UA",
  resources: {
    uk: { zod: translation },
  },
});
z.setErrorMap(zodI18nMap);

// export configured zod instance
export { z };
